<template>
	<div id="house-config">
		<Header2022 :navbg='true'></Header2022>
		<div class="config" v-if="houseDetails">
			<div class="left-wrap">
				<div class="price-wrap">
					<i class="total">{{houseDetails.children[projectIndex].product.price |toMoney}} 起</i>
					 <i class="separator">|</i>
					<i class="unit-price">{{houseDetails.children[projectIndex].product.pre_price |toMoney}}/m2 起</i>
					<i class="total">了解详情</i>
				</div>
				<img :src="imgSrc" >
			</div>
			<div class="config-wrap">
				<div class="house-type">
					<div class="name">{{houseDetails.name}}</div>
					<div class="houseDetails.specifications">{{houseDetails.another_name}}</div>
					<img :src="houseDetails.image_max">
					<div class="prompt">
						*本户型图所标尺寸仅供参考，交房尺寸以合同约定为准；相同户型单位因楼栋、楼层、单元等差别，局部结构、面积等可能不同。
					</div>
					<div class="type-btn">查看房屋详情</div>
				</div>
				<div class="house-location">
					<div class="name">房屋区位选择</div>
					<div class="project-list">
						<div class="project-item" v-for="(item,index) in houseDetails.children" :key="index"
							@click="projectTap(index)" :class="index==projectIndex?'active':''">
							<img :src="item.image_max">
							<div class="project-name">{{item.name}}</div>
							<div class="area">
								<i>{{item.another_name}}</i>
								<i>了解更多></i>
							</div>
						</div>
					</div>
				</div>
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane :label="optionsItem.name" :name="optionsItem.option_id + ''"
						v-for="(optionsItem,optionsIndex) in houseConfig"
						:key="optionsIndex">
						<div class="options-list">
							<div class="options-item" v-for="(valueItem,valueIndex) in optionsItem.option_values"
								:key="valueIndex" :class="[valueItem.is_close ? 'close' : '', activeIndex == valueIndex ? 'active' : '']" @click="valueTap(valueItem,valueIndex)">
								<div class="item-wrap">
									<div class="options-name">{{valueItem.option_value_name}}</div>
									<div class="price">
										<showPrice :price="valueItem.price" :precision="0"></showPrice>
									</div>
								</div>
						<!-- 		<div class="original-price" v-if="valueItem.quantity > valueItem.price">
									<showPrice :price="valueItem.quantity" :precision="0"></showPrice>
								</div> -->
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
				<div class="reserve-wrap">
					<div class="sale-name">限时活动</div>
					<div class="sale-intr">*立即预定即可参与5,000元抵扣20,000元优惠购房款活动 *现在订制可享9.9折优惠（不含选配产品）</div>
					<div class="type-btn" @click="reserveTap" :class="btnStatus>0 ? 'disabled' : ''">{{btnStatus>0?'暂时无货':'立即预定'}}</div>
					<div class="tips">支付意向金即可完成预订。在预订期间，意向金随时可退，7个工作日内到账。</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import utils from '@/common/utils'
	import Header2022 from '@/components/header2022.vue'
	import showPrice from '@/components/show-price.vue'
	import {
		mapState
	} from 'vuex'
	export default {
		components: {
			Header2022,
			showPrice
		},
		data() {
			return {
				houseDetails: null,
				projectIndex: 0,
				optionsIndex:0,	
			    activeIndex:0,
				activeName: '',
				imgSrc:'',
			}
		},
		computed: {
			...mapState(['houseApi']),
			houseConfig(){
				var houseConfig;
				if(this.houseDetails){
					houseConfig=this.houseDetails.children[this.projectIndex].product.option_values;
					this.activeName=houseConfig[this.optionsIndex].option_id + '';
					return houseConfig;
				}
			},
			btnStatus() {
				return this.houseConfig[this.optionsIndex].option_values[this.activeIndex].is_close;
			},
		},
		watch: {
			'$route': {
				handler: function(to) {
					if (to.name == "houseConfig" && to.params.id) {
						this.houseDetails=null;
						this.getData(to.params.id);
					}
				},
				immediate: true
			},
		},
		filters: {
			toMoney(val) {
				if (val)
					return new Intl.NumberFormat('zh', {
						style: 'currency',
						currency: 'CNY'
					}).format(val);
			},
		},
		methods: {
			projectTap(e){
				this.$store.commit('emptyHouse');
				this.projectIndex = e;
				this.imgSrc=this.houseDetails.children[this.projectIndex].image_max;
				var optionList=this.houseConfig[0].option_values;
				let defaultConfig = optionList.filter((item, index) => {
					if (item.is_default == 1) {
						this.activeIndex = index;
					}
				});
				for (var key of this.houseConfig) {
					for (var item of key.option_values) {
						if (item.is_default ==1) {
							this.$store.commit('updateHouseConfig', {
								[item.option_id]: item
							});
						}
					}
				}
			},
			valueTap(item,index) {
				this.activeIndex = index;
				this.imgSrc=this.houseConfig[this.optionsIndex].option_values[this.activeIndex].option_value_image;
				let activeConfig = null;
				if (item.is_close) {
					let defaultConfig = this.houseConfig[this.optionsIndex].option_values.filter(item => {
						return item.is_default == 1;
					});
					activeConfig = defaultConfig[0];
				} else {
					activeConfig = item;
				}
				this.$store.commit('updateHouseConfig', {
					[activeConfig.option_id]: activeConfig
				});
			},
			getData(id) {
				this.$axios.get(this.houseApi + 'pc/house/product/' + id)
					.then(res => {
						if (res.data.status == 1) {
							this.houseDetails = res.data.data;
							this.imgSrc=this.houseDetails.children[this.projectIndex].image_max;
							var optionList=this.houseConfig[0].option_values;
							let defaultConfig = optionList.filter((item, index) => {
								if (item.is_default == 1) {
									this.activeIndex = index;
								}
							});
							for (var key of this.houseConfig) {	
								for (var item of key.option_values) {
									if (item.is_default ==1) {
										this.$store.commit('updateHouseConfig', {
											[item.option_id]: item
										});
									}
								}
							}
						}
					})
			},
			reserveTap() {
				if(this.btnStatus>0){
					return false;
				}
				let houseInformation = {
					category_id: this.houseDetails.category_id,
					house_project_id: this.houseDetails.house_project_id,
					house_name: this.houseDetails.name,	
					house_another: this.houseDetails.another_name,
					project_id:this.houseDetails.children[this.projectIndex].product.product_id,
					project_name:this.houseDetails.children[this.projectIndex].name,
					project_address:this.houseDetails.children[this.projectIndex].another_name,
					project_image:this.houseDetails.children[this.projectIndex].image_max,
					futurePrices:this.houseDetails.children[this.projectIndex].product.price,
					intentionPrice:this.houseDetails.children[this.projectIndex].product.booked_price,
					unitPrice:this.houseDetails.children[this.projectIndex].product.pre_price
				};
				this.$store.commit('updateHouseInformation',houseInformation);
				this.$router.push({
					name: 'reserveOrder'
				});
			},
			handleClick(tab, event) {
				this.optionsIndex=tab.index;
				var options=this.houseConfig[tab.index].option_values;
				options.forEach((item, index) => {
					if (item.is_default == 1) {
						this.activeIndex = index;
					}
				});
			}
		}
	}
</script>

<style lang="scss">
	#house-config {
		color: #000000;
		.config {
			width: 100%;
			display: flex;
			height: calc(100vh - 60px);
			margin-top: 60px;
			overflow: hidden;
			.left-wrap {
				width: 78.3%;
				height: 100%;
				position: relative;
				img{
					width: 100%;
					height: 100%;
				}
				.price-wrap{
					background: #F4F4F4;
					border-radius: 10px 10px 0px 0px;
					position: absolute;
					bottom: 0;
					left:25%;
					font-size: 20px;
					height: 72px;
					line-height:72px;
					padding: 0 28px;
					.total{
						font-weight: 400;
						background: linear-gradient(10deg, #00C9FF 0%, #11ED84 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
					.unit-price{
						font-weight: 300;
						color: #B9B9B9;
						margin-right: 24px;
					}
					.separator{
						color:#B9B9B9;
						margin: 0 17px;
					}
				}
			}

			.config-wrap {
				height: 100vh;
				overflow-y: scroll;
				flex: 1;
				min-width: 416px;
				padding: 39px 23px 0;
				box-sizing: border-box;
				text-align: center;
				
				.name {
					font-size: 28px;
					font-weight: 800;
					line-height: 28px;
					margin: 100px 0 28px;
				}

				.specifications {
					font-size: 18px;
					color: #000000;
					line-height: 18px;
					margin: 14px 0 29px;
				}

				.prompt {
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 200;
					color: #000000;
					margin: 40px 0 50px;
				}

				.house-type {
					img {
						width: 100%;
						border-radius: 8px;
					}

					.type-btn {
						height: 44px;
						background: #F0F0F0;
						font-size: 16px;
						border-radius: 22px;
						line-height: 44px;
						width: 180px;
						text-align: center;
						margin: 0 auto;
					}
				}

				.house-location {
					margin-bottom: 100px;
					.project-item {
						padding: 9px 9px 18px;
						border: 2px solid #9B9B9B;
						border-radius: 12px;
						margin-bottom: 50px;
						text-align: left;

						&.active {
							border: 2px solid #00C9FF;
						}

						&:last-child {
							margin-bottom: 0px;
						}

						img {
							width: 100%;
							border-radius: 6px;
						}

						.project-name {
							font-size: 24px;
							font-weight: bold;
							line-height: 24px;
							margin: 13px 0 7px;
						}

						.area {
							font-size: 16px;
							font-weight: 300;
							color: #777777;
							display: flex;
							justify-content: space-between;
						}
					}
				}

				.options-list {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}
				.options-item {
					margin-bottom: 20px;
					width: 45%;
					.item-wrap {
						border: 1px solid rgba(238, 238, 238, 1);
						border-radius: 5px;
						padding: 20px 0;
					}
					&.active{
						.item-wrap {
							border: 1px solid #00C9FF;
						}
					} 
					&.close{
						.options-name,.price {
							color: #d0d0d0;
						}
					}
					
					&.active{
						&.close{
							.item-wrap {
								border: 1px solid rgba(238, 238, 238, 1);
								background-color: #f5f5f5;
								background-image: inherit;
							}
						} 
					} 
					.options-name {
						font-size: 16px;
					}

					.price {
						font-size: 18px;
						font-weight: 200;
					}
				}

				.reserve-wrap {
					text-align: left;

					.sale-name {
						font-size: 22px;
						font-weight: 800;
					}

					.sale-intr {
						font-size: 14px;
						font-weight: 400;
						line-height: 20px;
						margin: 8px 0 68px;
					}

					.tips {
						font-size: 14px;
						font-weight: 200;
						line-height: 20px;
						margin: 48px 0 136px;
					}

					.type-btn {
						height: 40px;
						font-size: 14px;
						border-radius: 20px;
						line-height: 40px;
						width: 100%;
						text-align: center;
						background: linear-gradient(16deg, #00C9FF 0%, #11ED84 100%);
						color: #FFFFFF;
						&.disabled{
							-webkit-filter: grayscale(100%);
							-moz-filter: grayscale(100%);
							-ms-filter: grayscale(100%);
							-o-filter: grayscale(100%);
							filter: grayscale(100%);
							filter: unUsed;
							filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
						}
					}
				}
			}
		}
	}
</style>
