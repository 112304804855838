<template>
	<div>{{ showPrice }}</div>
</template>

<script>
import utils from '@/common/utils'
export default {
	name: 'showPrice',
	props: {
		price: [Number, String],
		precision: {
			type: Number,
			default: 2
		}
	},
	computed: {
		showPrice() {
			// return new Intl.NumberFormat('zh', { style: 'currency', currency: 'CNY'}).format(this.price);
			return utils.toMoney(this.price);
		}
	}
};
</script>
